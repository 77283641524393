import React from "react"
import { graphql } from "gatsby"
import axios from "axios"
import handleScroll from "../functions/handleScroll"
import Header from "../components/header"
import Meta from "../components/meta"
import TitleHeader from "../components/titleHeader"
import SectionTitle from "../components/sectionTitle"
import BodyText from "../components/bodyText"
import ShadowImage from "../components/shadowImage.js"
import HighlightSection from "../components/highlightSection"
import Testimonial from "../components/testimonial"
import Positions from "../components/positions"
import Footer from "../components/footer"
import "../styles/pages/_careerPage.scss"
import "../styles/pages/_remoteCareers.scss"

export default class RemoteCareersPage extends React.Component {
  state = {
    loading: false,
    error: false,
    jobs: [],
  }

  fetchJobsData = () => {
    this.setState({ loading: true })
    axios
      .get(
        `https://api.resumatorapi.com/v1/jobs/status/open?apikey=${process.env.GATSBY_JAZZHR_API_KEY}`
      )
      .then(response => {
        let jobs = []
        response.data.map(job => {
          if (
            job.internal_code.toLowerCase() === "writer" &&
            job.country_id.toLowerCase() === "united states"
          ) {
            jobs.push(job)
          }
        })
        this.setState({
          loading: false,
          jobs: jobs,
        })
      })
      .catch(error => {
        this.setState({
          loading: false,
          error,
        })
      })
  }
  componentDidMount() {
    window.addEventListener("scroll", handleScroll)
    this.fetchJobsData()
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", handleScroll)
  }

  render() {
    const corporateCareersData = this.props.data
      .allContentfulCorporateCareersPage.nodes[0]
    const generalData = this.props.data.allContentfulGeneral.nodes[0]
    const corporateJobsData = this.props.data.allJazzhrJobs.nodes

    return (
      <div className="remote-careers career-page">
        <Meta
          title={corporateCareersData.seo.title}
          description={corporateCareersData.seo.description.description}
          image={corporateCareersData.seo.socialImage.file.url}
          url={this.props.location.href}
          imgAlt={corporateCareersData.seo.socialImage.description}
        />
        <Header />
        <TitleHeader title={corporateCareersData.pageTitle} />
        <div className="content">
          <div className="overview">
            <BodyText text={corporateCareersData.bodyText.json} />
            <div className="overlap-content">
              <ShadowImage
                image={corporateCareersData.mainImage}
                shadow={generalData.redShadow}
              />
            </div>
          </div>
          <div className="benefits-section">
            <SectionTitle title={corporateCareersData.benefitsTitle} />
            <HighlightSection
              background={corporateCareersData.benefitsBackground}
              highlights={corporateCareersData.benefits}
            />
          </div>
          <Testimonial
            image={corporateCareersData.testimonialImage}
            quoteIcon={corporateCareersData.testimonialQuoteIcon}
            quote={corporateCareersData.testimonialQuote.json}
            attribution={corporateCareersData.testimonialAttribution}
            shadow={generalData.darkShadow}
          />
          <div className="positions-section">
            <SectionTitle title={corporateCareersData.positionsTitle} />
            {this.state.loading ? (
              <Positions positions={corporateJobsData} />
            ) : this.state.error ? (
              <Positions positions={corporateJobsData} />
            ) : (
              <Positions positions={this.state.jobs} />
            )}
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export const query = graphql`
  query remoteCareersPageQuery {
    allContentfulCorporateCareersPage(
      filter: { name: { eq: "Remote Careers Page" } }
    ) {
      nodes {
        pageTitle
        bodyText {
          json
        }
        mainImage {
          description
          fluid {
            ...GatsbyContentfulFluid
          }
          file {
            url
          }
        }
        benefitsTitle
        benefits {
          tItle
          icon {
            description
            file {
              url
            }
          }
          summary {
            json
          }
        }
        benefitsBackground {
          description
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        testimonialImage {
          description
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        testimonialQuoteIcon {
          description
          file {
            url
          }
        }
        testimonialQuote {
          json
        }
        testimonialAttribution
        positionsTitle
        seo {
          title
          description {
            description
          }
          socialImage {
            description
            file {
              url
            }
          }
        }
      }
    }
    allContentfulGeneral {
      nodes {
        darkShadow {
          description
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        redShadow {
          description
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
    allJazzhrJobs(
      filter: {
        country_id: { eq: "United States" }
        internal_code: { eq: "writer" }
      }
    ) {
      nodes {
        title
        city
        state
        type
        department
        board_code
        country_id
      }
    }
  }
`
